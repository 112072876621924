<script setup lang="ts">
/** @ts-expect-error dawa is just a mess */
import * as dawaAutocomplete2 from 'dawa-autocomplete2'

const props = defineProps({
  type: String as () => 'HEAT_PUMP_INSTALLATION' | 'SOLAR',
})

const inputElm = ref(null)
const selectedAddress = ref<string | null>(null)

onMounted(() => {
  if (inputElm.value) {
    dawaAutocomplete2.dawaAutocomplete(inputElm.value, {
      select(selected: { tekst: string }) {
        selectedAddress.value = selected.tekst
      },
    })
  }
})
</script>

<template>
  <form class="relative lg:mt-8" @submit.prevent="navigateTo(props.type === 'HEAT_PUMP_INSTALLATION' ? `/overslag/${selectedAddress}` : `/overslag-solceller/${selectedAddress}`)">
    <div class="relative">
      <label for="dawa" class="sr-only">Indtast din adresse </label>
      <input
        id="dawa"
        ref="inputElm"
        name="address"
        placeholder="Indtast adresse"
        type="text"
        class="p-6 border border-blue-500 rounded-md w-full bg-blue-100"
        inputmode="text"
        autocomplete="off"
        aria-autocomplete="list"
        data-1p-ignore
      >
    </div>

    <div class="absolute top-0 right-0 bottom-0 flex pr-2">
      <div class="flex items-center">
        <button
          type="submit"
          class="p-4 text-white bg-blue-500 disabled:bg-opacity-50 rounded-md"
          :disabled="!selectedAddress"
          @click="navigateTo(props.type === 'HEAT_PUMP_INSTALLATION' ? `/overslag/${selectedAddress}` : `/overslag-solceller/${selectedAddress}`)"
        >
          <div class="h-5 w-5 flex items-center justify-center">
            <Icon name="heroicons-outline:arrow-right" />
          </div>
        </button>
      </div>
    </div>
  </form>
</template>
